import { throttle } from "lodash";
import eventBus from "./Events";
import { BASE_URL } from "../constants/ApiEndpointConstants";

class WebSocketUtil {
  constructor() {
    this.socket = null;
    this.heartbeatInterval = null;
    this.currentMessage = [];
  }

  connectWebSocket = (uid) => {
    // 如果 WebSocket 已经存在且处于打开状态，则不需要重新连接
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // console.log("当前连接仍然有效，不需要重新连接。");
      return;
    }

    let url = BASE_URL.replace("https://", "");

    this.socket = new WebSocket(`wss://${url}/wss`);

    this.socket.onopen = () => {
      console.log("WebSocket 连接成功");
      var message = "Hello Server!";
      let items = { uid: uid, message: message };
      this.socket.send(JSON.stringify(items));
      console.log(items);
    };
    this.socket.onmessage = this.handleMessage;

    this.socket.onclose = (e) => {
      console.log("WebSocket 连接已关闭", e);
      // 尝试重新连接
      this.connectWebSocket(uid);
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket 发生错误: ", error);
    };
  };

  handleMessage = (e) => {
    const mes = JSON.parse(e.data);
    console.log("接收到的消息:", mes);
    this.currentMessage = [e.data];
    if (mes.type === "ClientMessage") {
      eventBus.emit("webSocket receive the message!", mes);
    }
  };

  startHeartbeat = () => {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }

    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        var uid = "a1";
        var items = { uid: uid, message: "heartbeat" };
        this.socket.send(JSON.stringify(items));
        console.log(items);
      } else {
        // console.log("WebSocket 未连接，无法发送心跳包");
      }
    }, 30000); // 每30秒发送一次心跳包
  };

  sendMessage = (uid, messageContent, type) => {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const message = {
        uid: uid,
        type,
        message: messageContent,
      };
      this.socket.send(JSON.stringify(message));
      // console.log("消息已发送:", message);
    } else {
      // console.log("WebSocket 连接尚未打开或已关闭，无法发送消息");
    }
  };
}

export default WebSocketUtil;
