import React from "react";

const Tip = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#666",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8875"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M924.8 337.6c-22.6-53.4-54.9-101.3-96-142.4-41.1-41.1-89-73.4-142.4-96C631.1 75.8 572.5 64 512 64S392.9 75.8 337.6 99.2c-53.4 22.6-101.3 54.9-142.4 96-41.1 41.1-73.4 89-96 142.4C75.8 392.9 64 451.5 64 512s11.8 119.1 35.2 174.4c22.6 53.4 54.9 101.3 96 142.4s89 73.4 142.4 96C392.9 948.2 451.5 960 512 960s119.1-11.8 174.4-35.2c53.4-22.6 101.3-54.9 142.4-96 41.1-41.1 73.4-89 96-142.4C948.2 631.1 960 572.5 960 512s-11.8-119.1-35.2-174.4zM545.9 753.9c-8.7 8.7-20.7 14.1-33.9 14.1s-25.3-5.4-33.9-14.1c-8.7-8.7-14.1-20.7-14.1-33.9 0-26.5 21.5-48 48-48 13.3 0 25.3 5.4 33.9 14 8.7 8.7 14.1 20.7 14.1 33.9s-5.4 25.4-14.1 34zM480 544V256c0-17.7 14.3-32 32-32s32 14.3 32 32v288c0 17.7-14.3 32-32 32s-32-14.3-32-32z"
      fill={color}
      p-id="8876"
    ></path>
  </svg>
);

export default Tip;
