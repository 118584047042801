import React, { Component } from "react";
import spinner from "../../image/spinner.gif";
import load from "../../image/loader-loading.png";
import paypal from "../../image/paypal.gif";
import "../../style/common/loading.scss";
class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loadingShow } = this.props;
    return (
      <div className={`loading-mask ${loadingShow ? "show" : ""}`}>
        <div className="loading">
          <img src={paypal} alt="" className="load-gif"/>
          <div className="pay-txt">Securely logging you in ...</div>
        </div>
      </div>
    );
  }
}

export default Loading;
