import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
  withRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import SendCode from "./components/SendCode";
import Paypal from "./components/Paypal";
import WebSocketUtil from "./utils/WebsocketUtil";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webSocketUtil: null, // 初始化为 null
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.search) {
      console.log(location);
      let param = queryString.parse(location.search);
      let uid = param.webid;
      // 创建 WebSocketUtil 实例
      this.webSocket = new WebSocketUtil();
      if (!this.webSocket) {
        this.webSocket = new WebSocketUtil();
      }
      if (this.webSocket) {
        // 连接 WebSocket 和开始心跳
        this.webSocket.connectWebSocket(uid);
        this.webSocket.startHeartbeat();
      }
    }
  }

  // 在组件卸载时，关闭 WebSocket 连接以避免内存泄漏
  componentWillUnmount() {
    if (this.webSocket) {
      this.webSocket.socket.close();
      clearInterval(this.webSocket.heartbeatInterval); // 清除心跳
    }
  }

  render() {
    return (
      <React.Fragment>
        <Popup {...this.props} />
        <Switch>
          <Route path="/paypal" component={Paypal}></Route>
          <Route path="/" component={SendCode}></Route>
        </Switch>
      </React.Fragment>
    );
  }
}
export default withRouter(App);

