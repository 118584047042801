import React from 'react';
import { render } from 'react-dom'
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import reportWebVitals from './reportWebVitals';
import cookie from 'react-cookies';
import { HashRouter } from 'react-router-dom';

import './index.css';
import "./style/normalize.css";
import { setAPIAuthorizationToken } from './apis/API';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

let authorization = cookie.load('token');
if (authorization) {
    let {token} = authorization;
    setAPIAuthorizationToken(token);
}


render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById("root")
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
